import { Box, Grid2, Typography, useMediaQuery, useTheme } from "@mui/material";
import about_img from "../../images/about.png";

function AboutUs() {
    const theme = useTheme();
    const isMidScreen = useMediaQuery(theme.breakpoints.down("lg"));

    const cardData = [
        {
            title: "Fast Executions",
            description: "Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo erat amet",
        },
        {
            title: "Certified Expertise",
            description:
                "Our team includes AWS Solution Architects and Red Hat Certified professionals, ensuring that you receive the highest level of technical expertise.",
        },
        {
            title: "Proven Track Record",
            description:
                "With experience in government and well-known AWS projects, we bring a wealth of knowledge and a proven track record to every project.",
        },
        {
            title: "Dedicated Support",
            description:
                "We provide continuous support and guidance, ensuring that your IT infrastructure runs smoothly and efficiently.",
        },
        {
            title: "Cutting-Edge Technology",
            description:
                "We stay ahead of the curve by utilizing the latest technologies in virtualization, automation, and cloud computing to drive innovation in your business.",
        },
    ];

    return (
        <Grid2 size={12} container id="aboutus" p={isMidScreen ? 3 : 7}>
            {/* About Us Title */}
            <Grid2 size={12} mt={8}>
                <Typography
                    textAlign="center"
                    variant={isMidScreen ? "h4" : "h3"}
                    fontWeight="bold"
                    color="#0c0c5b"
                    gutterBottom
                >
                    About Us
                </Typography>
            </Grid2>

            {/* About Us Content */}
            <Grid2 size={12} container>
                <Box borderRadius={3} boxShadow={6} p={1}>
                    <Grid2 size={12} container columnSpacing={3}>
                        <Grid2 item size={{ xs: 12, md: 5 }} p={2}>
                            <img
                                src={about_img}
                                alt="Team collaboration and expertise"
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    borderRadius: "8px",
                                    objectFit: "cover",
                                }}
                            />
                        </Grid2>
                        <Grid2 item size={{ xs: 12, md: 7 }} alignContent="center" p={2} sx={{ color: "grey.600" }}>
                            <Typography fontSize={isMidScreen ? 17 : 20} gutterBottom>
                                At ItechExs, we are a team of dedicated IT professionals with a collective experience
                                of over 10 years in delivering top-notch solutions across various sectors, including
                                government projects and high-profile AWS deployments.
                            </Typography>
                            <Typography fontSize={isMidScreen ? 17 : 20} gutterBottom mt={3}>
                                We pride ourselves on our ability to offer comprehensive IT services that encompass
                                both cloud and on-premise environments, leveraging the latest technologies in
                                virtualization, web servers, and database management.
                            </Typography>
                        </Grid2>
                    </Grid2>
                </Box>
            </Grid2>

            {/* Why Choosing Us */}
            <Grid2 size={12} mt={6} container>
                <Grid2 item size={12}>
                    <Typography
                        textAlign="center"
                        variant={isMidScreen ? "h4" : "h3"}
                        fontWeight="bold"
                        color="#0c0c5b"
                        gutterBottom
                    >
                        Why Choosing Us!
                    </Typography>
                </Grid2>
                <Grid2
                    item
                    size={12}
                    container
                    rowSpacing={2}
                    columnSpacing={3}
                    mt={2}
                    justifyContent="center"
                >
                    {cardData.map((item, index) => (
                        <Grid2 key={index} item size={{ xs: 12, md: 6, lg: 4 }}>
                            <Box
                                sx={{
                                    backgroundColor: "white",
                                    boxShadow: 2,
                                    borderRadius: 3,
                                    p: 2,
                                    minHeight: { lg: "150px" }, // Dynamic height
                                }}
                            >
                                <Typography variant="h5" fontWeight="bold" color="#0c0c5b" gutterBottom>
                                    {item.title}
                                </Typography>
                                <Typography gutterBottom sx={{ color: "grey.600" }}>
                                    {item.description}
                                </Typography>
                            </Box>
                        </Grid2>
                    ))}
                </Grid2>
            </Grid2>
        </Grid2>
    );
}

export default AboutUs;
