import { Accordion, AccordionDetails, AccordionSummary, Grid2, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Services() {
    const theme = useTheme();
    const isMidScreen = useMediaQuery(theme.breakpoints.down("lg"));
    const services = [
        { title: 'Cloud Solutions', content: 'Maximize efficiency and reduce costs with our comprehensive cloud solutions. We provide tailored cloud architectures, including cloud storage, backup, and hosting services, to help your business seamlessly leverage the power of cloud technology.' }, // Replace with actual content
        { title: 'Linux Solutions', content: 'Unlock the potential of open-source technology with our Linux solutions. From deployment and configuration to management and support, we cover all major distributions including Ubuntu, RedHat, CentOS, and Amazon Linux, ensuring a secure and efficient environment.' }, // Replace with actual content
        { title: 'Infrastructure Management & Migration', content: 'Transform and modernize your IT landscape with our infrastructure management and migration services We handle the complete lifecycle of your infrastructure, ensuring smooth upgrades, migrations, and ongoing maintenance for uninterrupted business operations.' }, // Replace with actual content
        { title: 'Backup & Disaster Recovery Solutions', content: 'Protect your data and ensure business continuity with our backup and disaster recovery solutions. We offer advanced backup strategies and rapid recovery plans, designed to minimize downtime and safeguard your critical data against any potential threats.' }, // Replace with actual content
        { title: 'Centralized Identity Management', content: 'Enhance your IT security and streamline access management with our Active Directory setup services We deliver customized AD solutions that simplify user authentication, authorization, and policy enforcement, ensuring seamless integration across your entire network.' }, // Replace with actual content
    ];

    const [expanded, setExpanded] = useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Grid2 container spacing={2} mt={6} justifyContent="center" alignItems="center">
            <Grid2 item xs={12} id="services">
                <Typography textAlign="center" variant={isMidScreen ? "h4" : "h3"} fontWeight="bold" color="#0c0c5b" gutterBottom>
                    Our Services
                </Typography>
            </Grid2>
            {services.map((service, index) => (
                <Grid2 item xs={12} key={service.title}>
                    <Accordion expanded={expanded === service.title} onChange={handleChange(service.title)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: "#E8E8E8" }}>
                            <Typography variant="h6">{service.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                            {/* <Paper elevation={3} sx={{ p: 4 }}> */}
                            <Typography variant="body1" component="p" p={2} sx={{ color: "grey.600" }}>
                                {service.content}
                            </Typography>
                            {/* </Paper> */}
                        </AccordionDetails>
                    </Accordion>
                </Grid2>
            ))}
        </Grid2>
    );
}

export default Services;



//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



// import { Accordion, AccordionDetails, AccordionSummary, Grid2, Typography } from "@mui/material";
// import { useState } from "react";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// function Services() {
//     const services = [
//         { title: 'Cloud Solutions', content: 'Maximize efficiency and reduce costs with our comprehensive cloud solutions. We provide tailored cloud architectures, including cloud storage, backup, and hosting services, to help your business seamlessly leverage the power of cloud technology.' },
//         { title: 'Linux Solutions', content: 'Unlock the potential of open-source technology with our Linux solutions. From deployment and configuration to management and support, we cover all major distributions including Ubuntu, RedHat, CentOS, and Amazon Linux, ensuring a secure and efficient environment.' },
//         { title: 'Infrastructure Management & Migration', content: 'Transform and modernize your IT landscape with our infrastructure management and migration services We handle the complete lifecycle of your infrastructure, ensuring smooth upgrades, migrations, and ongoing maintenance for uninterrupted business operations.' },
//         { title: 'Backup & Disaster Recovery Solutions', content: 'Protect your data and ensure business continuity with our backup and disaster recovery solutions. We offer advanced backup strategies and rapid recovery plans, designed to minimize downtime and safeguard your critical data against any potential threats.' },
//         { title: 'Centralized Identity Management', content: 'Enhance your IT security and streamline access management with our Active Directory setup services We deliver customized AD solutions that simplify user authentication, authorization, and policy enforcement, ensuring seamless integration across your entire network.' },
//     ];

//     const [expandedPanels, setExpandedPanels] = useState([]);

//     const handleChange = (panel) => (event, isExpanded) => {
//         setExpandedPanels((prev) =>
//             isExpanded
//                 ? [...prev, panel] // Add to the expanded panels array
//                 : prev.filter((item) => item !== panel) // Remove if already expanded
//         );
//     };

//     return (
//         <Grid2 container spacing={2} id="services" justifyContent="center" alignItems="center">
//             <Grid2 item xs={12} mt={8}>
//                 <Typography textAlign="center" variant="h3" fontWeight="bold" color="#0c0c5b" gutterBottom>
//                     Our Services
//                 </Typography>
//             </Grid2>
//             {services.map((service) => (
//                 <Grid2 item xs={12} key={service.title}>
//                     <Accordion
//                         expanded={expandedPanels.includes(service.title)} // Check if the panel is expanded
//                         onChange={handleChange(service.title)}
//                     >
//                         <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{backgroundColor: "#E8E8E8"}}>
//                             <Typography variant="h6">{service.title}</Typography>
//                         </AccordionSummary>
//                         <AccordionDetails>
//                             {/* <Paper elevation={3} sx={{ p: 4 }}> */}
//                                 <Typography variant="body1" component="p" p={2}>
//                                     {service.content}
//                                 </Typography>
//                             {/* </Paper> */}
//                         </AccordionDetails>
//                     </Accordion>
//                 </Grid2>
//             ))}
//         </Grid2>
//     );
// }

// export default Services;
