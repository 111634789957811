import { AppBar, Box, IconButton, Toolbar, Typography, Link } from "@mui/material";
import ItechExsLogo from "../../images/ItechExsLogo.png";

function Header() {
    return (
        <AppBar
            position="fixed"
            color="transparent"
            elevation={0}
            sx={{
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                backdropFilter: "blur(10px)",
                zIndex: 1300,
                height: {
                    xs: "80px", // Increased height for small screens
                    sm: "70px", // Default height for medium and larger screens
                },
                pr: {
                    xs: 1, // Padding-right for small screens
                    sm: 0, // No padding-right for medium and larger screens
                },
            }}
        >
            <Toolbar
                sx={{
                    minHeight: {
                        xs: "80px", // Match the AppBar height for small screens
                        sm: "70px", // Default for medium and larger screens
                    },
                }}
            >
                <IconButton edge="start" sx={{ mr: 2 }}>
                    <img src={ItechExsLogo} alt="ItechExs" style={{ height: "50px" }} />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    {/* Optional Title */}
                </Typography>
                <Box sx={{ display: "flex", gap: 3 }}>
                    <Link
                        href="#home"
                        underline="none"
                        color="text.primary"
                        sx={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            "&:hover": {
                                color: "primary.main",
                                textDecoration: "underline",
                            },
                        }}
                    >
                        Home
                    </Link>
                    <Link
                        href="#aboutus"
                        underline="none"
                        color="text.primary"
                        sx={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            "&:hover": {
                                color: "primary.main",
                                textDecoration: "underline",
                            },
                        }}
                    >
                        About
                    </Link>
                    <Link
                        href="#services"
                        underline="none"
                        color="text.primary"
                        sx={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            "&:hover": {
                                color: "primary.main",
                                textDecoration: "underline",
                            },
                        }}
                    >
                        Services
                    </Link>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default Header;
