import { Box, Container, Grid2, IconButton, Typography, Link as MuiLink } from "@mui/material";
import { FaEnvelope, FaFacebookF, FaLinkedinIn, FaMapMarkerAlt, FaPhoneAlt, FaTwitter, FaYoutube } from "react-icons/fa";

function Footer() {
    return (
        <Grid2 size={12} sx={{ backgroundColor: "darkblue" }} pt={2}>

            {/* Office Location Header */}
            <Grid2 container justifyContent="center">
                <Grid2 item xs={12}>
                    <Typography variant="h4" textAlign="center" color="white" gutterBottom>
                        Our Office
                    </Typography>
                </Grid2>
            </Grid2>

            {/* Office Details */}
            <Grid2 container justifyContent="center" spacing={4} mt={2} px={2}>
                <Grid2 item xs={12} sm={4}>
                    <Typography color="white">
                        <FaMapMarkerAlt style={{ marginRight: 8 }} /> Ahmedabad, Gujarat, India
                    </Typography>
                </Grid2>
                <Grid2 item xs={12} sm={4}>
                    <Typography color="white">
                        <FaPhoneAlt style={{ marginRight: 8 }} /> +012 345 67890
                    </Typography>
                </Grid2>
                <Grid2 item xs={12} sm={4}>
                    <Typography color="white">
                        <FaEnvelope style={{ marginRight: 8 }} /> itechexs@gmail.com
                    </Typography>
                </Grid2>
            </Grid2>

            {/* Social Media Links */}
            <Grid2 container justifyContent="center" mt={2} mb={2}>
                <IconButton component="a" href="#" sx={{ color: "white", mx: 1 }}>
                    <FaTwitter />
                </IconButton>
                <IconButton component="a" href="#" sx={{ color: "white", mx: 1 }}>
                    <FaFacebookF />
                </IconButton>
                <IconButton component="a" href="#" sx={{ color: "white", mx: 1 }}>
                    <FaYoutube />
                </IconButton>
                <IconButton component="a" href="#" sx={{ color: "white", mx: 1 }}>
                    <FaLinkedinIn />
                </IconButton>
            </Grid2>
            {/* Copyright Section */}
            <Box sx={{ backgroundColor: "black", color: "white", py: 2 }}>
                <Container>
                    <Typography variant="body2" align="center">
                        &copy;{" "}
                        <MuiLink href="#" underline="always" color="inherit">
                            ItechExs
                        </MuiLink>
                        , All Rights Reserved.
                    </Typography>
                </Container>
            </Box>
        </Grid2>

    );
}
export default Footer;
// <Grid2 size={12} container>
//     <Grid2 item size={12} container sx={{ backgroundColor: "darkblue" }}>
//         <Grid2 item size={12} mt={3}>
//             <Typography textAlign="center" variant="h4" fontWeight="bold" color="white" gutterBottom>
//                 Our Office
//             </Typography>
//         </Grid2>

//         {/* Office Details */}
//         <Grid2 container size={12} sx={{ justifyContent: 'center' }} columnSpacing={5}>
//             <Grid2 item xs={12} sm={4} p={5}>
//                 <Typography color="white">
//                     <FaMapMarkerAlt style={{ marginRight: 8 }} />
//                     Ahmedabad, Gujarat, India
//                 </Typography>
//             </Grid2>
//             <Grid2 item xs={12} sm={4} p={5}>
//                 <Typography color="white">
//                     <FaPhoneAlt style={{ marginRight: 8 }} />
//                     +012 345 67890
//                 </Typography>
//             </Grid2>
//             <Grid2 item xs={12} sm={4} p={5}>
//                 <Typography color="white">
//                     <FaEnvelope style={{ marginRight: 8 }} />
//                     itechexs@gmail.com
//                 </Typography>
//             </Grid2>
//         </Grid2>

//         {/* Social Media Links */}
//         <Grid2 container size={12} justifyContent="center" mb={3}>
//             <IconButton
//                 component="a"
//                 href="#"
//                 sx={{ color: "white", border: "1px solid white", mx: 1 }}
//             >
//                 <FaTwitter />
//             </IconButton>
//             <IconButton
//                 component="a"
//                 href="#"
//                 sx={{ color: "white", border: "1px solid white", mx: 1 }}
//             >
//                 <FaFacebookF />
//             </IconButton>
//             <IconButton
//                 component="a"
//                 href="#"
//                 sx={{ color: "white", border: "1px solid white", mx: 1 }}
//             >
//                 <FaYoutube />
//             </IconButton>
//             <IconButton
//                 component="a"
//                 href="#"
//                 sx={{ color: "white", border: "1px solid white", mx: 1 }}
//             >
//                 <FaLinkedinIn />
//             </IconButton>
//         </Grid2>
//     </Grid2>
//     <Grid2 item size={12} container justifyContent="center" sx={{ backgroundColor: "black" }} p={5}>
//         <Typography variant="h6" align="center" fontWeight="bolt" color="white">
//             &copy;{" "}
//             <a href="#" sx={{ color: "inherit", textDecoration: "underline" }}>
//                 ItechExs
//             </a>
//             , All Rights Reserved.
//         </Typography>
//     </Grid2>
// </Grid2>